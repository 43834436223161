import React from "react";
import { connect } from "react-redux";

import {
  addTimesToCart,
  getUpgrades,
  resetOrder,
  addExchTimeSlot,
  addToCartN,
  selectProductN,
} from "../Actions/actionCreator";

import TopBar from "../Components/TopBar";
import ProductCard from "../Components/ProductCard";
import SelectionHeader from "../Components/SelectionHeader";
import TimeSlots from "../Components/TimeSlot/TimeSlots";
import Button from "../Components/Button";
import {
  TimeSelectionWrapper,
  TimeSelectionBlock,
  CardWrapper,
  ButtonWrapper,
} from "../Styles/ViewStyles/TimeSlotSelectionStyles";
import { getTixInfoForCard } from "../Utils/Helpers/timeSlotSelectionHelper";
import { GenericModal } from "../Components/Modal";
import Loading from "../Components/Loading";

class TimeSlotSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      step: 1,
      invIds: [],
      showCvttModal: false,
    };

    this.handleClick = () => {
      const {
        cart,
        currentTime,
        currentProduct,
        addTimesToCart,
        resetOrder,
        getUpgrades,
        isExchange,
        addExchTimeSlot,
        addToCartN,
        selectProductN,
        upgradeFlag,
        history,
      } = this.props;
      const { step, invIds } = this.state;

      const newStep = step + 1;

      if (newStep <= invIds.length) {
        this.onTimeSlotSelect();
        return;
      }

      if (upgradeFlag) {
        addToCartN(cart, cart);
        selectProductN(currentProduct);
      }

      if (!currentTime?.[invIds[step - 1]]) {
        this.setState({ modal: true });
        return;
      }

      if (!isExchange) {
        const selectedTime = currentProduct.isMultiTimed
          ? currentTime
          : currentTime?.[Object.keys(currentTime)[0]];
        addTimesToCart(cart, currentProduct.id, selectedTime);
        resetOrder();
        if (!currentProduct.isMultiTimed) {
          getUpgrades(cart, currentTime.start, true);
        } else {
          history.push("/Cart");
        }
      } else {
        addExchTimeSlot();
      }
    };

    this.closeModal = () => {
      this.setState({ modal: false });
    };

    this.closeCvttModal = () => {
      this.setState({ showCvttModal: false });
    };

    this.onTimeSlotSelect = () => {
      this.setState({ step: this.state.step + 1 });
    };

    this.backHandler = () => {
      this.setState({ step: this.state.step - 1 });
    };
  }

  componentDidMount() {
    const { availTimes, product } = this.props;
    const invIds = Object.keys(availTimes.slotIds).map((id) => +id);
    this.setState({ invIds, showCvttModal: product.isMultiTimed });
  }

  componentDidUpdate(prevProps) {
    const { isKioskAllowedToUse, history, availTimes } = this.props;
    if (!isKioskAllowedToUse) {
      history.push("/");
    }
    const invIds = Object.keys(availTimes.slotIds).map((id) => +id);
    const { availTimes: prevAvailtimes } = prevProps;
    const prevInvIds = Object.keys(prevAvailtimes.slotIds).map((id) => +id);
    if (
      invIds.length !== prevInvIds.length ||
      !invIds.every((value, index) => value === prevInvIds[index])
    ) {
      this.setState({ invIds });
    }
  }

  render() {
    const {
      product,
      availTimes,
      currentTime,
      isExchange,
      client,
      cart,
      currentProduct,
      slotStructure,
      isLoading,
      exchangeType,
    } = this.props;
    const { invIds, step } = this.state;

    const names = {
      sn: "SPACE NEEDLE",
      cgg: "CHIHULY GARDEN AND GLASS",
    };

    const productId = invIds?.[step - 1];

    const currentPlace = /space needle/i.test(currentProduct.name)
      ? names.sn
      : /chihuly garden/i.test(currentProduct.name)
        ? names.cgg
        : client !== "cgg"
          ? names.sn
          : names.cgg;

    const simpleHeader = "CONFIRM YOUR " + currentPlace + " ENTRY TIME";
    const cvttheader = availTimes?.slotStructureLabels?.find(
      (labelData) => labelData.productId === productId
    );
    const header = currentProduct.isMultiTimed
      ? `CONFIRM YOUR ${cvttheader?.label}`
      : simpleHeader;

    let longDesc;
    if (
      currentTime?.id &&
      availTimes.productInventoryModels?.length &&
      !isExchange
    ) {
      longDesc = getTixInfoForCard(
        cart,
        currentProduct,
        currentTime,
        slotStructure
      );
    } else if (
      currentTime?.id &&
      availTimes.productInventoryModels?.length &&
      isExchange
    ) {
      longDesc = { __html: product.longDesc };
    }

    const imageUrl = currentProduct.isMultiTimed
      ? availTimes.slotStructureLabels?.find(
          (labelData) => labelData.productId === productId
        )?.imageUrl
      : product.imageUrl;

    return (
      <>
        <GenericModal
          label="Please select an entry time to continue."
          open={this.state.modal}
          click={this.closeModal}
          client={client}
        />
        <GenericModal
          label="Please plan for one hour visiting each location."
          open={this.state.showCvttModal}
          click={this.closeCvttModal}
          client={client}
        />
        <div>
          <TopBar
            title={exchangeType}
            backHandler={
              currentProduct.isMultiTimed &&
              this.state.step !== 1 &&
              this.backHandler
            }
          />
          <SelectionHeader header={header} />
          {isLoading ? (
            <Loading />
          ) : (
            <TimeSelectionWrapper>
              <TimeSelectionBlock>
                <div className="outerTimeSlotDiv">
                  <div className="innerTimeSlotDiv">
                    {availTimes.slotIds?.[productId]?.slotIdNames?.map((d) => (
                      <TimeSlots key={d.id} slotId={d} productId={productId} />
                    ))}
                  </div>
                </div>
                <CardWrapper>
                  <ProductCard
                    cart={true}
                    prodName={product.name}
                    subTitle={product.shortDesc}
                    alternateText={longDesc}
                    img={imageUrl}
                    focus
                    page={3}
                    step={currentProduct.isMultiTimed && step}
                  />
                  <ButtonWrapper>
                    <Button
                      click={this.handleClick}
                      label="CONTINUE"
                      width="100%"
                      height="12.5vh"
                      second={client === "cgg" ? false : true}
                      client={client}
                    />
                  </ButtonWrapper>
                </CardWrapper>
              </TimeSelectionBlock>
            </TimeSelectionWrapper>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDate: state.selectedDate,
    productList: state.productList,
    product: state.currentProduct,
    availTimes: state.availTimes,
    cart: state.cart,
    cartN: state.cartN,
    currentTime: state.currentTime,
    currentProduct: state.currentProduct,
    slotStructure: state.availTimes.slotStructPriceAdjustmentModels,
    isKioskAllowedToUse: state.isKioskAllowedToUse,
    isExchange: state.isExchange,
    upgradeFlag: state.upgradeFlag,
    client: state.client,
    isLoading: state.isLoading,
    exchangeType: state.exchangeType,
  };
};

const mapDispatchToProps = {
  addTimesToCart,
  getUpgrades,
  resetOrder,
  addExchTimeSlot,
  addToCartN,
  selectProductN,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlotSelection);
