import React from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { countPromoQty } from "../Utils/Helpers/helpers";
import { selectProduct } from "../Actions/actionCreator";

import {
  PlusMinusBox,
  CountBox,
  HcardWrapper,
  InformationBox,
  QtyChangeToolsWrapper,
  EditBoxWrapper,
} from "../Styles/ComponentStyles/HorizontalSelectionProductCardStyles";

let editIcon = require("../Images/EditIconWhite.png");

const HorizontalProductCard = (props) => {
  const {
    changeCount,
    name,
    currentProductId,
    plusMinus,
    cart,
    mainProd,
    prodId,
    totalCount,
    selectProduct,
    productList,
    client,
    isExchange,
  } = props;

  const editHandler = () => {
    const newProd = productList.find((p) => p.id === currentProductId);
    selectProduct(newProd);
    props.history.push("/QtySelection");
  };

  let { promo } = props;
  let count;
  if (promo) {
    const promoCount = countPromoQty(promo);
    count = mainProd
      ? totalCount - promoCount
      : promo[prodId]
        ? promo[prodId]
        : 0;
  } else {
    promo = [];
    count = 0;
  }

  return (
    <HcardWrapper $cart={cart}>
      <InformationBox $client={client}>
        <div>{name?.toUpperCase()}</div>
      </InformationBox>

      <QtyChangeToolsWrapper>
        {plusMinus ? (
          <>
            <PlusMinusBox
              onClick={() => changeCount(mainProd, prodId, -1)}
              $client={client}
            >
              <FontAwesomeIcon icon={faMinus} size="2x" />
            </PlusMinusBox>
            <CountBox $plusMinus={true} $client={client}>
              <h2>{count}</h2>
            </CountBox>
            <PlusMinusBox
              onClick={() => changeCount(mainProd, prodId, 1)}
              $client={client}
            >
              <FontAwesomeIcon icon={faPlus} size="2x" />
            </PlusMinusBox>{" "}
          </>
        ) : (
          <>
            <CountBox $cart={cart} $client={client}>
              <h2>{count}</h2>
            </CountBox>
            {!isExchange && (
              <EditBoxWrapper onClick={editHandler} $client={client}>
                <img src={editIcon} width={"27px"} alt="" />
              </EditBoxWrapper>
            )}
          </>
        )}
      </QtyChangeToolsWrapper>
    </HcardWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    productList: state.fullProductList,
    client: state.client,
    isExchange: state.isExchange,
  };
};

const mapDispatchToProps = { selectProduct };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HorizontalProductCard);
