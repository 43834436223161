import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  resetCart,
  addToCart,
  addToCartN,
  selectProductN,
  selectProduct,
  selectUpgrade,
  fetchProductAvailTimes,
  selectTime,
  selectTimeN,
} from "../Actions/actionCreator";

import TopBar from "../Components/TopBar";
import HPIC from "../Components/HorizontalProductInfoCard";

import {
  UpgradeContainer,
  UpgradeWrapper,
  UpgradeTitle,
} from "../Styles/ViewStyles/UpgradesStyles";
import Loading from "../Components/Loading";
import cloneDeep from "../Utils/Helpers/cloneDeep";

class Upgrades extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      isTimedUpgrade: false,
    };

    this.handleClick = async (id) => {
      const {
        cart,
        addToCart,
        selectedDate,
        resetCart,
        selectProduct,
        currentProduct,
        fullProductList,
        selectUpgrade,
        fetchProductAvailTimes,
        selectTime,
      } = this.props;

      const newCart = cloneDeep(cart);
      let newProd;

      if (id === currentProduct.id) {
        newCart.products.forEach((p) => (p.productId = currentProduct.id));
        selectProduct(currentProduct);
        resetCart();
        addToCart(newCart, cart);
      } else {
        selectTime(null, {});
        newProd = fullProductList.find((p) => p.id === id);
        const newProdPromotionsIds = newProd.promotions.map(
          (promo) => promo.promoId
        );
        newCart.products.forEach((p) => {
          p.productId = id;
          const newAppliedPromo = p.appliedPromotions.filter((promoObj) =>
            newProdPromotionsIds.includes(promoObj.promotionId)
          );
          p.appliedPromotions = newAppliedPromo;
          return p;
        });
        selectProduct(newProd);
        resetCart();
        addToCart(newCart, cart);

        if (newProd.timedYN) {
          await fetchProductAvailTimes(id, selectedDate);
          selectUpgrade(true);
        }
      }

      this.setState({
        redirect: true,
        isTimedUpgrade: !!newProd?.timedYN,
      });
    };
  }

  componentDidMount() {
    const {
      currentProduct,
      addToCart,
      selectProduct,
      cart,
      upgradeFlag,
      currentProductN,
      addToCartN,
      selectProductN,
      upgradeProducts,
      fetchProductAvailTimes,
      selectedDate,
      currentTime,
      currentTimeN,
      selectTimeN,
      selectTime,
    } = this.props;

    if (!Object.keys(currentTime).length) {
      selectTime(currentProduct.id, currentTimeN);
    }

    if (!upgradeProducts) {
      this.props.history.goBack();
      return this.props.history.goBack();
    }

    const newCart = cloneDeep(cart);

    if (!upgradeFlag) {
      newCart.products.forEach((p) => (p.productId = currentProduct.id));
      addToCartN(newCart, cart);
      selectProductN(currentProduct);
      selectTimeN(currentProduct.id, currentTime);
    } else {
      newCart.products.forEach((p) => (p.productId = currentProductN.id));
      addToCart(newCart, cart);
      selectProduct(currentProductN);
      selectTime(currentProductN.id, currentTimeN);
      fetchProductAvailTimes(currentProductN.id, selectedDate);
    }
  }

  render() {
    if (!this.props.isKioskAllowedToUse) {
      return <Redirect exact push to={"/"} />;
    }

    if (this.state.redirect) {
      if (this.state.isTimedUpgrade) {
        return <Redirect push to={"/EntryTime"} />;
      } else {
        return <Redirect push to={"/Cart"} />;
      }
    }

    const { upgradeProducts, currentProduct, client, currentTime } = this.props;

    const isCurrentTimePresent = currentTime ? Object.keys(currentTime) : null;

    return (
      <>
        <TopBar />
        {!upgradeProducts || !isCurrentTimePresent ? (
          <Loading />
        ) : (
          <UpgradeWrapper>
            <UpgradeTitle $client={client}>
              <h5>SEE MORE SEATTLE</h5>
              <h5>
                HAVE TIME BEFORE YOUR VISIT? UPGRADE NOW AND SAVE TIME AND
                MONEY!
              </h5>
            </UpgradeTitle>
            <UpgradeContainer>
              {upgradeProducts.map((item, i) => (
                <HPIC product={item} handleClick={this.handleClick} key={i} />
              ))}
              <HPIC
                product={currentProduct}
                last
                handleClick={this.handleClick}
              />
            </UpgradeContainer>
          </UpgradeWrapper>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.currentProduct,
    cart: state.cart,
    cartN: state.cartN,
    currentProduct: state.currentProduct,
    currentProductN: state.currentProductN,
    upgradeProducts: state.upgradeProducts,
    selectedDate: state.selectedDate,
    fullProductList: state.fullProductList,
    isKioskAllowedToUse: state.isKioskAllowedToUse,
    visitDate: state.selectedDate,
    upgradeFlag: state.upgradeFlag,
    client: state.client,
    currentTime: state.currentTime,
    currentTimeN: state.currentTimeN,
  };
};

const mapDispatchToProps = {
  fetchProductAvailTimes,
  addToCart,
  resetCart,
  selectProduct,
  selectUpgrade,
  selectProductN,
  addToCartN,
  selectTime,
  selectTimeN,
};

export default connect(mapStateToProps, mapDispatchToProps)(Upgrades);
