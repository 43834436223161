import styled, { css } from "styled-components";

import { getStyle } from "../../../Utils/Helpers/getStyleOrResources";

export const AttractionTab = styled.div`
  position: relative;
  height: 47px;
  margin: 10px 15px;
  padding: 5px;
  width: ${(props) => (props.$client === "cgg" ? "182px" : "230px")};
  background-color: ${(props) => getStyle(props.$client).primary};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: ${(props) =>
    props.$client !== "cgg"
      ? getStyle(props.$client).boldFont
      : getStyle(props.$client).font};
  font-size: ${(props) => (props.$client === "cgg" ? "14px" : null)};

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.$active &&
    css`
      background-color: ${(props) =>
        props.$client !== "cgg"
          ? getStyle(props.$client).secondary
          : getStyle(props.$client).primaryOrange};
      transform: all 2s ease;
      &:after {
        content: "";
        position: absolute;
        bottom: -20px;
        border: 10px solid transparent;
        border-top: 10px solid
          ${props.$client !== "cgg"
            ? getStyle(props.$client).secondary
            : getStyle(props.$client).primaryOrange};
      }
    `}
`;

export const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 75px;
`;
