import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4,
  p {
    font-family: ${(props) => getStyle(props.$client).boldFont};
  }

  p {
    color: gray;
  }
`;
