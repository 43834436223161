import styled from "styled-components";

import { getStyle } from "../../../Utils/Helpers/getStyleOrResources";

export const DayPartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80px;
  justify-content: center;
  background: ${(props) => getStyle(props.$client).primary};
  color: black;
  margin: 0 auto;
  h5,
  h6 {
    margin: 2px auto;
    color: ${(props) => getStyle(props.$client).fontColor};
    font-size: 1.4em;
  }

  h5 {
    font-family: ${(props) => getStyle(props.$client).boldFont};
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
  }
`;
