import React from "react";
import classnames from "classnames";

import {
  AttractionTab,
  TabWrapper,
} from "../../Styles/ComponentStyles/ProductSelection/AttractionTabStyles";
import { connect } from "react-redux";

import { getResources } from "../../Utils/Helpers/getStyleOrResources";

const AttractionSelector = (props) => {
  const { currentTab, changeTab, client } = props;
  const info = getResources(client);

  const toggle = (tab) => {
    changeTab(tab);
  };

  return (
    <TabWrapper>
      <AttractionTab
        onClick={() => {
          toggle("1");
        }}
        $active={currentTab === "1"}
        className={classnames({ active: currentTab === "1" })}
        $client={client}
      >
        {info.name.toUpperCase()}
      </AttractionTab>
      <AttractionTab
        onClick={() => {
          toggle("2");
        }}
        $active={currentTab === "2"}
        className={classnames({ active: currentTab === "2" })}
        $client={client}
      >
        OTHER ATTRACTIONS
      </AttractionTab>
    </TabWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTab: state.currentTab,
    client: state.client,
  };
};
export default connect(mapStateToProps)(AttractionSelector);
