import React from "react";
import { Countries } from "../Utils/Countries/countries_lib";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ScrollContainer from "react-indiana-drag-scroll";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown as caret } from "@fortawesome/free-solid-svg-icons";

import TopBar from "../Components/TopBar";
import Button from "../Components/Button";

import { GenericModal } from "../Components/Modal";
import {
  getHeaders,
  AddTicketSocket,
  AddBocaSocket,
  createNewWebSocket,
} from "../Actions/actionCreator";

import {
  InputArea,
  AuthHeader,
  InputWrapper,
  KeyboardWrapper,
} from "../Styles/ViewStyles/ZipCodeStyles";
import Loading from "../Components/Loading";

class ZipCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCountry: "",
      zipcode: "",
      error: "",
      isLoading: false,
    };
    this.keyboard = React.createRef();
  }

  modalClose = () => {
    this.setState({ error: "" });
  };

  onSelectCountry = (e) => {
    this.setState({
      dropdownOpen: false,
      selectedCountry: {
        id: e.target.id,
        name: e.target.name,
      },
    });

    if (
      this.state.selectedCountry.name === "United States" ||
      this.state.selectedCountry.name === "Canada"
    ) {
      this.setState({ zipcode: "" });
    } else {
    }
    if (this.keyboard.current !== null) {
      this.keyboard.current.clearInput();
    }
  };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  openDrop = () => {
    this.setState({
      dropdownOpen: true,
    });
  };

  onChange = (input) => {
    this.setState({ zipcode: input });
  };

  onSubmit = async () => {
    const { pendingOrderId, client, history } = this.props;
    const { selectedCountry, zipcode } = this.state;

    if (zipcode.length > -1) {
      const { API } = this.props;
      let body = {
        GuestCountry: selectedCountry.id,
        GuestPostalCode: zipcode,
      };
      const headers = getHeaders(client);
      headers.headers.Accept = "application/json";

      this.setState({ isLoading: true });
      axios
        .put(
          `${API}/api/kiosk/${pendingOrderId}/countryAndZipcode`,
          body,
          headers
        )
        .then(() => {
          history.push("/Printing");
        })
        .catch((err) => {
          this.props.sentDataToLogs("zipcode save error: ", err);
          this.setState({
            isLoading: false,
            error:
              "Sorry, an error occurred processing your postal code. Please verify your entry and try again.",
          });
          console.error(err);
        });
    } else {
      this.setState({
        error: "Please make sure you have input your zipcode.",
      });
    }
  };

  onKeyPress = (button) => {
    if (button === "{enter}") {
      this.onSubmit();
    }
  };

  onHandleCountrySelect = (e) => {};

  render() {
    const { error, isLoading, dropdownOpen, selectedCountry, zipcode } =
      this.state;

    return (
      <>
        <GenericModal
          label={error}
          open={!!error}
          click={this.modalClose}
          client={this.props.client}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TopBar backOnly />
            <AuthHeader $client={this.props.client}>
              <h4>Authorizing </h4>
            </AuthHeader>
          </div>
          <InputWrapper>
            <InputArea>
              <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                  tag="div"
                  data-toggle="dropdown"
                  aria-expanded={dropdownOpen}
                  className="dropToggle"
                  open={dropdownOpen}
                >
                  {selectedCountry
                    ? `${selectedCountry.name}`
                    : "Select your country "}
                  <FontAwesomeIcon icon={caret} size="sm" />
                </DropdownToggle>
                <DropdownMenu>
                  <ScrollContainer className="container" hideScrollbars={false}>
                    {Countries.map((cntry, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          id={cntry.id}
                          name={cntry.name}
                          onClick={(e) => {
                            this.onSelectCountry(e);
                          }}
                        >
                          {" "}
                          {cntry.name}
                        </DropdownItem>
                      );
                    })}
                  </ScrollContainer>
                </DropdownMenu>
              </Dropdown>
              {selectedCountry.name === "United States" ||
              selectedCountry.name === "Canada" ? (
                <input
                  type="text"
                  name="zipcode"
                  placeholder="ZIP/POSTAL CODE"
                  className="zipcode"
                  value={zipcode}
                  autoFocus={selectedCountry === "" ? false : true}
                  disabled={true}
                  autoComplete="off"
                />
              ) : (
                <div></div>
              )}
            </InputArea>
            {selectedCountry.name === "United States" ||
            selectedCountry.name === "Canada" ? (
              <KeyboardWrapper>
                <Keyboard
                  keyboardRef={(r) => (this.keyboard.current = r)}
                  maxLength={selectedCountry.name === "United States" ? 5 : 6}
                  layout={name}
                  layoutName={
                    selectedCountry.name === "United States"
                      ? "nums"
                      : "default"
                  }
                  display={{
                    "{bksp}":
                      '<i class="fas fa-backspace" style="color: red"></i>',
                    "{enter}":
                      '<i class="fas fa-sign-in-alt" style="color: green"></i>',
                  }}
                  mergeDisplay={true}
                  onChange={this.onChange}
                  onKeyPress={this.onKeyPress}
                />
              </KeyboardWrapper>
            ) : null}
          </InputWrapper>
        </div>
        {((selectedCountry.name === "United States" && zipcode.length === 5) ||
          (selectedCountry.name === "Canada" && zipcode.length === 6) ||
          !(
            selectedCountry.name === "United States" ||
            selectedCountry.name === "Canada"
          )) &&
        selectedCountry !== "" ? (
          <div
            style={{
              height: "20vh",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "15%",
            }}
          >
            {isLoading ? (
              <div style={{ width: "25%" }}>
                <Loading isFull />
              </div>
            ) : (
              <Button
                second={this.props.client !== "cgg" ? true : false}
                bold
                label="SUBMIT"
                width={"25%"}
                height={"12.5vh"}
                click={this.onSubmit}
                client={this.props.client}
              />
            )}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pendingOrderId: state.orderStatus.orderId,
  client: state.client,
  API: state.API,
  error: state.error,
});

const mapDispatchToProps = {
  AddBocaSocket,
  AddTicketSocket,
  createNewWebSocket,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ZipCode)
);

let name = {
  default: [
    "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
    "Q W E R T Y U I O P",
    "A S D F G H J K L {enter}",
    "Z X C V B N M",
  ],
  nums: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"],
};
