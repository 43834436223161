import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const PromoWrapper = styled.div`
  display: flex;
  margin: 0 7%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px auto 0;
  justify-content: center;
  align-items: center;
`;

export const TotalCountBox = styled.div`
  padding: 20px 30px;
  width: 100%;
  color: #fff;
  background-color: ${(props) => getStyle(props.$client).primary};
  margin: 10px auto 0;
  display: flex;
  flex-direction: column;

  .top {
    margin-bottom: 0;
  }

  .bottom {
    margin: 1% 0;
    font-family: ${(props) => getStyle(props.$client).boldFont};
    font-size: 23px;
  }

  p {
    margin-bottom: 0;
    font-family: ${(props) => getStyle(props.$client).font};
    font-size: ${(props) => (props.$client === "cgg" ? "14px" : "")};
  }
`;

export const HorizontalPromoWrapper = styled.div`
  width: 660px;
`;

export const LoadingCartWrapper = styled.div`
  width: 100%;
  max-width: 340px;
  margin: 10px auto 0;
`;
