import styled, { keyframes } from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

const ellipsis = keyframes`
    25% { content: " . "}
    50% { content: " . ."}
    75% { content: " . . ."}
`;
const inWard = keyframes`
 from { transform: translateX(-100%)}
 to {transform: translateX(0%)}
`;

export const InputArea = styled.div`
  width: 100%;
  height: 50px;
  margin: 25px auto;
  display: flex;
  justify-content: center;

  .dropdown {
    display: flex;
    height: 100%;
    font-size: 1.2em;
    text-indent: 10px;
    width: 40%;
    background-color: #fff;
  }

  .dropToggle {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-menu {
    width: calc(100% + 180px);
    margin-left: -90px;
    background: white;
    flex-direction: column;
    height: 60vh;
    -webkit-overflow-scrolling: touch;
    border-bottom: none;

    button {
      color: black;
      height: 45px;
    }
  }

  .container {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .zipcode {
    margin-left: 15px;
    padding: 0;
    height: 100%;
    font-size: 1.2em;
    text-indent: 10px;
    width: 40%;
    border: none;
    user-select: none;

    :disabled {
      background-color: white;
      color: black;
    }
  }
`;

export const AuthHeader = styled.div`
  width: 70%;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  background-color: ${(props) => getStyle(props.$client).secondaryColor};
  color: ${(props) => getStyle(props.$client).fontColor};
  margin: 12px 0 35px;

  h4 {
    margin: 0;
    width: 100%;
    text-align: center;

    &::after {
      content: " ";
      position: absolute;
      right: 2;
      animation: ${ellipsis} 2s linear infinite;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 2%;
  flex-direction: column;
`;

export const KeyboardWrapper = styled.div`
  width: 95%;
  margin: 5% auto;
  border-radius: 5px;
  animation: ${inWard} 1s ease-out;

  .simple-keyboard.hg-theme-default .hg-button {
    height: 70px;
    width: 100px;
  }
`;
