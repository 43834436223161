import React from "react";
import { connect } from "react-redux";

import Button from "../Components/Button";
import {
  ButtonWrapper,
  PrinterWrapper,
} from "../Styles/ComponentStyles/PrinterDownStyles";
import { handleAgreeOnlyDigital } from "../Actions/actionCreator";

const PrinterDown = ({ client, handleAgreeOnlyDigital }) => {
  const handleClick = () => {
    handleAgreeOnlyDigital(true);
  };

  return (
    <PrinterWrapper $client={client}>
      <>
        <h4>Only digital ticket are currently available at this kiosk</h4>

        <ButtonWrapper>
          <Button
            click={handleClick}
            label="Continue"
            client={client}
            long
            second={client !== "cgg"}
          />
        </ButtonWrapper>
      </>
    </PrinterWrapper>
  );
};

const mapStateToProps = (state) => ({
  client: state.client,
});

const mapDispatchToProps = {
  handleAgreeOnlyDigital,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrinterDown);
