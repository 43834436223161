import styled, { keyframes } from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

const inWard = keyframes`
 from { transform: translateX(-100%)}
 to {transform: translateX(0%)}
`;

export const ScanimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55%;
  height: 10%;
  animation: ${inWard} 1s ease-out;

  h3 {
    color: white;
    text-align: center;
    margin-bottom: 3%;
    font-family: ${(props) => getStyle(props.$client).boldFont};
  }
  img {
    width: 60%;
  }
`;

export const Wrapper = styled.div`
  .modal {
    background-color: none;
  }
`;

export const ExchangeButtonsWrapper = styled.div`
  & > :last-child {
    margin-top: 15px;
  }
`;
