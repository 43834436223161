import React from "react";
import axios from "axios";
import { connect } from "react-redux";

import swipeImage from "../Images/card.png";
import { GenericModal } from "../Components/Modal";
import Button from "../Components/Button";
import TopBar from "../Components/TopBar";
import { ExchangeNumPad } from "../Components/Exchage/NumPadModal";

import {
  createCityPassExchangeOrder,
  getHeaders,
  setError,
  selectTime,
  setCurrentDate,
  setExchangeMode,
} from "../Actions/actionCreator";
import Loading from "../Components/Loading";
import {
  Wrapper,
  ScanimationWrapper,
  ExchangeButtonsWrapper,
} from "../Styles/ComponentStyles/ExchangeStyles";
import { getStyle } from "../Utils/Helpers/getStyleOrResources";
import SelectionHeader from "../Components/SelectionHeader";

let swipe = "";
class Exchange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      barcodes: [],
      modal: { isOpened: false, label: "" },
      numPad: false,
      isLoading: false,
      barcode: "",
      isTryingMixCityPasses: false,
    };

    this.ProcessBarcode = async (voucherBarcode) => {
      const voucher = voucherBarcode.slice(0, 19);
      const { API, client, setExchangeMode, exchangeType } = this.props;
      const headers = getHeaders(client);

      this.setState({ isLoading: true });

      var arr = [];
      arr.push(voucher);
      switch (voucher.charAt(5)) {
        case "0":
          arr.push(this.replaceAt(voucher, 5, "5"));
          arr.push(this.replaceAt(voucher, 5, "9"));
          break;
        case "5":
          arr.push(this.replaceAt(voucher, 5, "0"));
          arr.push(this.replaceAt(voucher, 5, "9"));
          break;
        case "9":
          arr.push(this.replaceAt(voucher, 5, "0"));
          arr.push(this.replaceAt(voucher, 5, "5"));
          break;
        default:
          break;
      }
      var isDuplicate = false;
      for (let i = 0; i < arr.length; i++) {
        if (this.state.barcodes.includes(arr[i])) {
          isDuplicate = true;
          break;
        }
      }

      const incorrectVoucher = !voucher || voucher.length < 19;
      if (isDuplicate || incorrectVoucher) {
        this.setState({
          modal: {
            isOpened: true,
            label: incorrectVoucher
              ? "Enter valid CityPASS ticket number."
              : "This voucher has already been scanned.",
          },
          isLoading: false,
        });
        return;
      }

      const voucherExchangeType = voucher?.startsWith("30845878")
        ? "CityPass C3"
        : "CityPass";

      if (exchangeType && exchangeType !== voucherExchangeType) {
        this.setState({ isTryingMixCityPasses: true, isLoading: false });
        return;
      }

      axios
        .post(
          `${API}/api/kiosk/cityPassVoucher/${voucher}/validate/${voucherExchangeType.replaceAll(" ", "")}`,
          null,
          headers
        )
        .then((response) => {
          if (response.data.valid) {
            let arr = [...this.state.barcodes];

            this.setState({
              isLoading: false,
              modal: {
                isOpened: true,
                label: "Would you like to scan another CityPASS barcode?",
              },
              barcodes: arr.concat(voucher),
            });
            if (!exchangeType) {
              setExchangeMode(voucherExchangeType);
            }
            return;
          }
          this.setState({
            isLoading: false,
            modal: { isOpened: true, label: "Validation of voucher failed." },
          });
        })
        .catch((err) => {
          console.error("error", err);
          this.setState({
            isLoading: false,
            modal: {
              isOpened: true,
              label: "Error ocured during voucher validation. Try again.",
            },
          });
        });
    };

    this.replaceAt = (voucher, index, replacement) => {
      if (index >= voucher.length) {
        return voucher;
      }

      return (
        voucher.substring(0, index) + replacement + voucher.substring(index + 1)
      );
    };

    this.closeModal = () => {
      const { setError, error } = this.props;
      if (error) {
        setError();
      }
      const barcode = this.state.barcode?.length < 19 ? this.state.barcode : "";
      this.setState({
        modal: { isOpened: false, label: "" },
        barcode,
      });
    };

    this.closeMixError = () => {
      this.setState({ isTryingMixCityPasses: false });
    };

    this.scanComplete = () => {
      const { barcodes } = this.state;
      const { createCityPassExchangeOrder } = this.props;

      createCityPassExchangeOrder(barcodes);
      this.setState({
        modal: { isOpened: false, label: "" },
        barcode: this.state.barcode?.length < 19 ? this.state.barcode : "",
        barcodes: [...barcodes],
      });
    };

    this.changeInputType = () => {
      this.setState({ numPad: !this.state.numPad, barcode: "" });
    };

    this.onkeypress = (e) => {
      if (!this.state.modal.isOpened) {
        let code = e.keyCode ? e.keyCode : e.which;
        const key = String.fromCharCode(code);
        swipe += key;
        if (code === 13) {
          this.ProcessBarcode(swipe);
          swipe = "";
        }
      }
    };

    this.onKeyPress = (input) => {
      const barcode = this.state.barcode || "";
      const deleteLast = input === "{bksp}";
      if (deleteLast) {
        this.setState({ barcode: barcode.slice(0, barcode.length - 1) });
        return;
      }
      const newBarcode = barcode + input;
      this.setState({ barcode: newBarcode });
      return;
    };
  }

  componentDidMount() {
    this.props.selectTime(null, {});
    this.props.setCurrentDate();
    window.addEventListener("keypress", this.onkeypress, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.onkeypress, false);
  }

  componentDidUpdate() {
    const { error } = this.props;
    const { modal, barcodes } = this.state;
    if (error && !modal.isOpened) {
      this.setState({
        modal: { isOpened: true, label: error },
        barcodes: [...barcodes],
      });
    }
  }

  render() {
    const { client, voucherIsLoading, exchangeType } = this.props;
    const { modal, isLoading, numPad, barcodes, isTryingMixCityPasses } =
      this.state;
    const isModalQuestion = modal?.label.slice(-1) === "?";
    const isC3 = exchangeType.includes("C3");

    return (
      <Wrapper>
        <TopBar backOnly title={exchangeType} />
        <SelectionHeader />
        {isTryingMixCityPasses && (
          <GenericModal
            label={`Invalid CityPASS ${isC3 ? "C3" : ""} barcode entered. Different types of CityPASS cannot be combined
              in the same order. If you would like to redeem your CityPASS ${!isC3 ? "C3" : ""} please start a new
              order after completing this one.`}
            open={true}
            click={this.closeMixError}
            client={client}
          />
        )}
        {isLoading || voucherIsLoading ? (
          <Loading />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
            }}
          >
            <GenericModal
              label={modal.label}
              open={modal.isOpened}
              click={this.closeModal}
              click2={isModalQuestion ? this.scanComplete : null}
              client={client}
              question={isModalQuestion}
              scannedTicketsCount={isModalQuestion && barcodes.length}
            />
            {!numPad ? (
              <ScanimationWrapper $client={client}>
                <h3>
                  SCAN TICKET BELOW <br /> Scan your CityPASS ticket barcode{" "}
                  <br /> to exchange for a timed ticket{" "}
                </h3>
                <img src={swipeImage} alt="Card Swipe" />
              </ScanimationWrapper>
            ) : (
              <ExchangeNumPad
                onKeyPress={this.onKeyPress}
                value={this.state.barcode}
                click={(e) => this.ProcessBarcode(e)}
                client={client}
              />
            )}
            <div
              style={{
                width: "30%",
                color: "white",
                fontFamily: `${getStyle(client).font}`,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: "1.2em", maxWidth: 300 }}>
                {!numPad
                  ? "If your barcode is not working, please enter your ticket number manually."
                  : "To scan your ticket, tap the button below."}
              </p>
              <ExchangeButtonsWrapper>
                <Button
                  click={this.changeInputType}
                  second={client !== "cgg" && !numPad}
                  label={!numPad ? "ENTER TICKET NUMBER" : "SCAN TICKET"}
                  width="260px"
                  height="80px"
                  bold
                  client={client}
                  style={{ marginBottom: 5 }}
                />
                {this.state.barcodes.length > 0 ? (
                  <Button
                    label="REDEEM TICKETS"
                    width="260px"
                    height="80px"
                    click={this.scanComplete}
                  />
                ) : null}
              </ExchangeButtonsWrapper>
            </div>
          </div>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    API: state.API,
    error: state.error,
    voucherIsLoading: state.isLoading,
    exchangeType: state.exchangeType,
  };
};

const mapDispatchToProps = {
  createCityPassExchangeOrder,
  setError,
  selectTime,
  setCurrentDate,
  setExchangeMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Exchange);
