import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const UpgradeContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  border-radius: 10px;
  height: 100%;
  max-height: 80vh;
`;

export const UpgradeButton = styled.button`
  background-color: ${(props) => getStyle(props.$client).primary};
`;

export const UpgradeWrapper = styled.div`
  margin: 40px 7% 0 7%;
`;

export const UpgradeTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  h5 {
    font-family: ${(props) => getStyle(props.$client).boldFont};
  }
`;
