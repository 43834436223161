import React from "react";
import { connect } from "react-redux";
import {
  formatTime,
  formatDateAndTimeOfVisit,
  getFirstSelectedDate,
} from "../Utils/Helpers/dateHelpers";

import {
  UpgradeButton,
  PromotionCard,
  InfoCardWrapper,
  ProductImage,
} from "../Styles/ComponentStyles/HorizontalProductInfoCardStyles";

const HorizontalInfoCard = (props) => {
  const {
    last,
    product,
    handleClick,
    client,
    productList,
    currentProduct,
    currentTime,
    selectedDate,
  } = props;

  const id = last ? product.id : product.upgradeProductId;
  const filterImage = productList.filter((item) => item.id === id);

  const image = filterImage[0].imageUrl || "";
  const title = last ? currentProduct.name : product.upgradeTitle;

  const currentDateTime = getFirstSelectedDate(currentTime);
  const currentTimeDesc = formatDateAndTimeOfVisit(currentDateTime);
  const selectedDateDesc = formatDateAndTimeOfVisit(selectedDate, true);
  const finalDate = currentProduct.timedYN ? currentTimeDesc : selectedDateDesc;
  const currentProductDescription = "Proceed with entry on " + finalDate;
  const upgradedProductDescription = product?.upgradeDescription?.replace(
    "*|selectedTime|*",
    `${formatTime(currentTime.start)}`
  );
  const text = last ? currentProductDescription : upgradedProductDescription;

  return (
    <PromotionCard $client={client}>
      <InfoCardWrapper>
        <ProductImage
          style={{
            background: `url(${image}) center center / 100% 100% no-repeat`,
          }}
        />
        <div className="info-text">
          <h6>{title}</h6>
          <p> {text} </p>
        </div>
      </InfoCardWrapper>
      <UpgradeButton onClick={() => handleClick(id)} $client={client}>
        <h6
          style={{
            fontSize: "1.2em",
          }}
        >
          SELECT <br /> {product.visualDiffInPrice}
        </h6>
      </UpgradeButton>
    </PromotionCard>
  );
};

const mapStateToProps = (state) => {
  return {
    productList: state.fullProductList,
    currentProduct: state.currentProduct,
    currentTime: state.currentTime,
    selectedDate: state.selectedDate,
    client: state.client,
  };
};

export default connect(mapStateToProps)(HorizontalInfoCard);
