import styled from "styled-components";

import { getStyle } from "../../../Utils/Helpers/getStyleOrResources";

export const SingleSlotWrapper = styled.div`
  width: 100%;
  height: 60px;
  color: black;
  background-color: ${(props) =>
    props.$active === "active"
      ? getStyle(props.$client).primaryLight
      : props.$active === "selected"
        ? props.$client !== "cgg"
          ? getStyle(props.$client).secondary
          : getStyle(props.$client).primaryOrange
        : getStyle(props.$client).disabled};
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid ${(props) => getStyle(props.$client).primary};
  color: ${(props) =>
    props.$active === "active"
      ? getStyle(props.$client).fontColor
      : props.$active === "selected"
        ? getStyle(props.$client).fontColor
        : "gray"};

  font-family: ${(props) => getStyle(props.$client).boldFont};

  &:hover {
    background-color: ${(props) => getStyle(props.$client).primaryDark};
  }
  p {
    margin: 0 auto;
  }
`;
