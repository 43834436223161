import styled, { css } from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$page === 1 ? "378px" : "344px")};
  background: white;
  max-height: ${(props) => (props.$height ? props.$height : "525px")};
  height: ${(props) => (props.$page === 1 ? "525px" : "auto")};
  opacity: 1;
  margin: 0 auto;
  transition: all 0.5s ease;
  transform: scale(0.75);

  h3 {
    font-size: 1.2em;
    font-family: ${(props) => getStyle(props.$client).boldFont};
  }
  .descText {
    width: 100%;
    height: 100%;
    font-family: ${(props) => getStyle(props.$client).boldFont};
    span {
      font-family: ${(props) => getStyle(props.$client).font};
    }
  }
  ${(props) =>
    props.$focus &&
    css`
      opacity: 1;
      h3 {
        font-size: 1.2em;
        font-family: ${(props) => getStyle(props.$client).boldFont};
      }
      border-bottom: ${props.$page === 1
        ? props.$client === "cgg"
          ? "solid 10px #db5a26"
          : "solid 10px #b0904c"
        : ""};

      .descText {
        width: 100%;
        height: 100%;
        font-family: ${(props) => getStyle(props.$client).boldFont};
        span {
          font-family: ${(props) => getStyle(props.$client).font};
        }
      }

      transform: scale(1);
      transition: scale 0.3s ease;
    `};

  ${(props) =>
    props.$cart &&
    css`
      opacity: 1;
      color: black;
      transform: scale(1)
      transition: scale .3s ease;

      h3{
          font-size: 1.2em;
          font-family: ${(props) => getStyle(props.$client).boldFont}
      }

      h6{
        font-size: 1.2em;
      }
  `}
`;

export const ProdImage = styled.div`
  background: url(${(props) => (props.$img ? props.$img : "")});

  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: ${(props) =>
    props.$page === 2
      ? "220px"
      : props.$page === 3
        ? "200px"
        : props.$page === 4
          ? "190px"
          : "241px"};
`;

export const CartTicketsTable = styled.table`
  width: 100%;
  font-family: ${(props) => getStyle(props.$client).boldFont};

  tbody {
    white-space: nowrap;
  }

  tr {
    line-height: 1.2;
  }

  td {
    vertical-align: baseline;
    padding-right: 9px;

    &:nth-child(3) {
      vertical-align: middle;
      padding: 0;
    }

    span {
      line-height: 1;
    }
  }
`;

export const StepLabel = styled.div`
  position: absolute;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-family: gothamBold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
`;
