import React from "react";
import { connect } from "react-redux";

import {
  addToCart,
  getUpgrades,
  fetchProductAvailTimes,
  setError,
  addTimesToCart,
  selectTime,
} from "../Actions/actionCreator";
import { convertCart } from "../Utils/Helpers/helpers";

import TopBar from "../Components/TopBar";
import SelectionHeader from "../Components/SelectionHeader";
import HorizontalSelectionProductCard from "../Components/HorizontalSelectionProductCard";
import Button from "../Components/Button";
import ProductCard from "../Components/ProductCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Components/Loading";
import { GenericModal } from "../Components/Modal";

import {
  PromoBodyWrapper,
  HorizontalPromoCardWrapper,
  TotalTicketsBox,
  ProductCardWrapper,
  ButtonWrapper,
} from "../Styles/ViewStyles/QtySelectionStyles";
import cloneDeep from "../Utils/Helpers/cloneDeep";

const NO_TIME_SLOTS_MESSAGE =
  "There are no time slots available for the chosen date.";

class QtySelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      promo: {},
      thisCart: {
        orderId: null,
        orderComment: null,
        products: [],
      },
      modal: { isOpened: false, text: "" },
    };

    this.ClickHandle = () => {
      const {
        addToCart,
        currentProduct,
        cart,
        availTimes,
        history,
        upgradeProducts,
        selectedDate,
        addTimesToCart,
      } = this.props;
      const { thisCart } = this.state;
      if (currentProduct.timedYN && !availTimes.productInventoryModels.length) {
        this.setState({
          modal: {
            isOpened: true,
            text: NO_TIME_SLOTS_MESSAGE,
          },
        });
        return;
      }

      if (thisCart.products.length) {
        addToCart(thisCart, cart);
        const timed = currentProduct.timedYN;

        if (timed) {
          return history.push("/EntryTime");
        }

        const todayDate =
          selectedDate.getFullYear() +
          "-" +
          ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
          "-" +
          selectedDate.getDate();
        addTimesToCart(thisCart, currentProduct.id, { start: todayDate });
        if (upgradeProducts) {
          return history.push("/Upgrades");
        }
        return history.push("/Cart");
      }

      this.setState({
        modal: {
          isOpened: true,
          text: "Please select at least one ticket to continue",
        },
      });
    };

    // Changes the total count in the cart for the tickets
    // Parameters: 1- prod = main product trigger (true/false), 2- id of promotion or product, 3- direction of change (+1/-1)
    this.changeCount = (prod, id, dir) => {
      const { currentProduct, getUpgrades } = this.props;
      const baseTicket = {
        productId: currentProduct.id,
        appliedPromotions: [],
      };
      const newCart = { ...this.state.thisCart };
      if (dir > 0) {
        if (!prod) {
          baseTicket.appliedPromotions.push({ promotionId: id });
        }
        newCart.products.push(baseTicket);
      } else {
        const remove = prod
          ? newCart.products.findIndex(
              (item) => item.appliedPromotions.length === 0
            )
          : newCart.products.findIndex(
              (item) =>
                item.appliedPromotions.length &&
                item.appliedPromotions[0].promotionId === id
            );
        if (remove > -1) {
          newCart.products = newCart.products.filter(
            (item, index) => index !== remove
          );
        }
      }
      const simplifiedCart = convertCart(newCart, currentProduct.id);
      const newPromo = prod ? { ...this.state.promo } : simplifiedCart.promos;
      let tzOffset = new Date().getTimezoneOffset() * 60000;
      const date = new Date(this.props.visitDate - tzOffset)
        .toISOString()
        .slice(0, -1);
      this.setState({
        thisCart: newCart,
        totalCount: simplifiedCart.qty,
        promo: newPromo,
      });
      if (newCart.products.length && !currentProduct.isMultiTimed) {
        getUpgrades(newCart, date);
      }
    };

    this.closeModal = () => {
      const { error, setError, history } = this.props;
      if (error) {
        setError();
        history.push("/ProductSelection");
      }
      this.setState({ modal: { isOpened: false } });
    };
  }

  async componentDidMount() {
    const {
      cart,
      currentProduct,
      selectedDate,
      fetchProductAvailTimes,
      selectTime,
    } = this.props;
    const { id, timedYN } = currentProduct;
    if (timedYN) {
      fetchProductAvailTimes(id, selectedDate);
      selectTime(null, {});
    }
    if (cart.products.length) {
      const simplifiedCart = convertCart(cloneDeep(cart), currentProduct.id);
      this.setState({
        thisCart: cart,
        totalCount: simplifiedCart.qty,
        promo: simplifiedCart.promos,
      });
    }
  }

  componentDidUpdate() {
    const { history, isKioskAllowedToUse } = this.props;
    if (!isKioskAllowedToUse) {
      history.push("/");
    }
  }

  render() {
    const { totalCount, promo, modal } = this.state;
    const { currentProduct, client, isLoading, error } = this.props;
    const { promotions, longDesc } = currentProduct;

    const label = modal.text || error;
    const open = modal.isOpened || !!error;

    return (
      <>
        <GenericModal
          label={label}
          open={open}
          click={this.closeModal}
          client={client}
        />
        <div style={{ color: "white" }}>
          <TopBar />
          <SelectionHeader
            header={"TICKET ORDER"}
            subHeader={"SELECT THE NUMBER OF TICKETS FOR EACH PRODUCT"}
          />
          <PromoBodyWrapper>
            <HorizontalPromoCardWrapper>
              <HorizontalSelectionProductCard
                name={currentProduct.displayLabel}
                plusMinus
                changeCount={this.changeCount}
                mainProd
                prodId={currentProduct.id}
                totalCount={totalCount}
                promo={promo}
              />
              {promotions?.map((item) => {
                return (
                  <HorizontalSelectionProductCard
                    name={item.name}
                    key={item.promoId}
                    plusMinus
                    changeCount={this.changeCount}
                    prodId={item.promoId}
                    totalCount={totalCount}
                    promo={promo}
                    currentCart={this.state.thisCart}
                  />
                );
              })}

              <TotalTicketsBox $client={client}>
                <h4>
                  {client !== "sn" && (
                    <FontAwesomeIcon icon={faTag} flip="horizontal" />
                  )}{" "}
                  {totalCount} Total Tickets
                </h4>
              </TotalTicketsBox>
            </HorizontalPromoCardWrapper>
            <ProductCardWrapper>
              <ProductCard
                id={currentProduct.id}
                prodText={currentProduct.longDesc}
                img={currentProduct.imageUrl}
                prodName={currentProduct.name}
                subTitle={currentProduct.shortDesc}
                alternateText={{ __html: longDesc }}
                focus
                page={2}
              />
              <ButtonWrapper>
                {isLoading ? (
                  <Loading isFull={true} />
                ) : (
                  <Button
                    click={this.ClickHandle}
                    label="CONTINUE"
                    second={client !== "cgg" ? true : false}
                    width="100%"
                    height="12.5vh"
                    client={client}
                  />
                )}
              </ButtonWrapper>
            </ProductCardWrapper>
          </PromoBodyWrapper>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentProduct: state.currentProduct,
    cart: state.cart,
    upgradeProducts: state.upgradeProducts,
    visitDate: state.selectedDate,
    isKioskAllowedToUse: state.isKioskAllowedToUse,
    client: state.client,
    availTimes: state.availTimes,
    selectedDate: state.selectedDate,
    isLoading: state.isLoading,
    error: state.error,
  };
};

const mapDispatchToProps = {
  addToCart,
  getUpgrades,
  fetchProductAvailTimes,
  setError,
  addTimesToCart,
  selectTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(QtySelection);
